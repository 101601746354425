import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { system, propTypes } from './lib/system'

const Divider = styled.div`
  background-color: ${themeGet('colors.primary')};
  margin-bottom: ${themeGet('space.base')};
  width: 100px;

  ${system}
`

Divider.defaultProps = {
  height: '10px',
}

Divider.propTypes = {
  ...propTypes,
}

export default Divider
