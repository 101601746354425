import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { system } from './lib/system'

const PostDate = styled.time`
  color: ${themeGet('colors.medium')};
  display: block;
  font-size: ${themeGet('fontSizes.xs')};
  font-style: italic;
  margin-bottom: ${themeGet('space.base')};

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    font-size: ${themeGet('fontSizes.s')};
  }

  ${system}
`

export default PostDate
