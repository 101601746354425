import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import Posts from '../../components/Posts'
import SEO from '../../components/SEO'

function BlogArchivePage(props = {}) {
  const posts = props.data.allMdx.edges
  return (
    <Layout>
      <SEO title="Archive - Blog" />
      <PageHeader
        title="Blog"
        description="On design, development, productivity, leadership, and more."
      />
      <Posts posts={posts} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
            tags
          }
        }
      }
    }
  }
`

export default BlogArchivePage
