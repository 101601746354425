import React from 'react'
import { Link } from 'gatsby'

import Box from '../styled/Box'
import Divider from '../styled/Divider'
import FlexGrid from '../styled/FlexGrid'
import PostDate from '../styled/PostDate'
import Tags from '../components/Tags'

function Posts(props = {}) {
  return (
    <FlexGrid display={{ _: 'block', md: 'flex' }} gutter="l">
      {props.posts.map((post, idx) => {
        const { frontmatter, excerpt } = post.node
        const hasTags = frontmatter.tags.length >= 0

        return (
          <FlexGrid.Item key={idx} as="article" flexBasis="50%" mb="xl">
            <Divider height="1px" width="100%" />
            <PostDate>{frontmatter.date}</PostDate>
            <Box as="h2" mb="xs">
              <Box as={Link} to={frontmatter.path} textDecoration="none">
                {frontmatter.title}
              </Box>
            </Box>
            <Box as="p" fontSize="s" mb="s">
              {excerpt}{' '}
              <Box as={Link} to={frontmatter.path}>
                Keep reading&hellip;
              </Box>
            </Box>
            {hasTags && <Tags tags={frontmatter.tags} />}
          </FlexGrid.Item>
        )
      })}
    </FlexGrid>
  )
}

export default Posts
